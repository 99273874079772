import { ITeamsModel } from '@models/Competition';
import { IUserInfoModel } from '@models/User';
import { IAuthCredentials, IAuthModel, ISession } from '@models/AuthUser';
import { IAPIResponse, AbstractAPI, EnumAPIStatus, IAPIPaginationResponse } from '@api/AbstractAPI';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IFilterClassification, IFilterList, IFilterPublicAssetsList } from '@models/Content';
import { IDashboardResult } from '@models/Dashboard';

@Injectable({
    providedIn: 'root'
})
export class WebService extends AbstractAPI {

    public serverSessionLossSubject: Subject<boolean>;

    public ServerSessionLoss: Observable<boolean>;

    constructor(protected HttpClient: HttpClient) {
        super(HttpClient);

        this.serverSessionLossSubject = new Subject<boolean>();

        this.ServerSessionLoss = this.serverSessionLossSubject.asObservable();
    }

    //#region AUTHENTICATION METHODS
    /**
     * 
     * @param data 
     * @returns 
     */
    async Login(data: IAuthCredentials): Promise<IAPIResponse<IAuthModel>> {

        // nop futuro para multiplos projetos será necessario passar a global de modo a identificar o utilizador para o projeto
        const submit =
        {
            // global: this.Global,
            // data: {
            username: data.Username,
            password: data.Password
            // }
        };

        try { return await this.RequestPOST<IAuthModel>("Backoffice/Auth/Login", submit); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async RecoverPassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Backoffice/Auth/RecoverPassword", data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ResetPassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Backoffice/Auth/ResetPassword", data)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    async ChangePersonalData(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Backoffice/Auth/ChangeName", data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ChangePassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Backoffice/Auth/ChangePassword", data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetUserInfo(): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestGET<IUserInfoModel>("Backoffice/User/GetInfo")); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetUsersList(filters: IFilterList): Promise<IAPIPaginationResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`Backoffice/User/List?PageNumber=${filters?.PageNumber || ''}&PageRows=${filters?.PageRows || ''}&Text=${filters.Text || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetUsersById(id: number): Promise<IAPIResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestGET<any[]>(`Backoffice/User/Get?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data
     * @returns 
     */
    async SaveUser(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`Backoffice/User/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteUser(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`Backoffice/User/Delete?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param userIdList 
     * @returns 
     */
    async UserGenerateNewPassword(userIdList: string[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/User/GenerateNewPassword`, userIdList)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param userIdList 
     * @returns 
     */
    async UserSendEmailToDefinePassword(userIdList: string[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/User/SendEmailToDefinePassword`, userIdList)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async Logout(): Promise<IAPIResponse<boolean>> {

        try { return this.handleAuthResponse(await this.RequestGET<boolean>("Backoffice/Auth/Logout")); }
        catch (error) { return error; }
    }
    //#endregion


    //#region EDITION METHODS

    /**
     * 
     * @returns 
     */
    async GetBaseEditionList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>("Backoffice/Common/CalendarYear/GetBaseList")); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param filter 
     * @returns 
     */
    async GetEditionList(filter?: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>("Backoffice/Race/Edition/GetList", filter)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetEditionById(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Race/Edition/Get?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param form 
     * @returns 
     */
    async SaveEdition(form): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Race/Edition/Save`, form)); }
        catch (error) { return error; }
    }

    async DeleteEdition(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Race/Edition/Delete?id=${id}`)); }
        catch (error) { return error; }
    }
    //#endregion


    //#region PERMISSIONS

    /**
     * 
     * @param idParent 
     * @param idScope 
     * @returns 
     */
    async GetSimpleMenuListPermissions(idParent?: string, idScope?: number): Promise<IAPIResponse<any>> {

        const params: string = `idParent=${idParent || ''}&idScope=${idScope || ''}&isPublic=${''}&isTabNavigation=${''}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Menu/GetSimpleList?${params}`)); }
        catch (error) { return error; }
    }

    //#endregion


    //#region COMMON METHODS
    /**
     * 
     * @param formData 
     * @returns 
     */
    async UploadAndGetUrl(formData?: FormData): Promise<IAPIResponse<string>> {

        try { return this.handleAuthResponse(await this.RequestPOSTFormData<string>("Backoffice/Generic/File/UploadAndGetUrl", formData)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetContentTypeList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/ContentType/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetGenreList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Generic/Gender/GetList`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @returns 
     */
    async GetEntityList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Common/Entity/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetRoleUsersList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/User/Role/GetList`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param id 
     * @returns 
     */
    async GetRoleUserPermissionTemplate(id: string = null): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/User/Permissions/GetTemplate?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idUserRole 
     * @returns 
     */
    async GetRoleUserPermissionListTemplate(idUserRole: number = null): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/User/Permissions/GetTemplateList?idUserRole=${idUserRole || ''}`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveRoleUserPermissionListTemplate(data: any): Promise<IAPIResponse<any>> {

        const params = data;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/User/Permissions/SaveTemplate`, params)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteRoleUserPermissionTemplate(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/User/Permissions/DeleteTemplate?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetPlatformList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Generic/GetPlatformList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCalendarYear 
     * @returns 
     */
    async GetDashboardData(idCalendarYear?: string): Promise<IAPIResponse<IDashboardResult>> {

        try { return this.handleAuthResponse(await this.RequestGET<IDashboardResult>(`Backoffice/Generic/Dashboard/Get?idCalendarYear=${idCalendarYear}`)); }
        catch (error) { return error; }
    }
    /**
     * devolve a lista de menus que o utilizador tem acesso
     * @returns 
     */
    async GetNavigationList(): Promise<IAPIResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestGET<any[]>("Backoffice/Menu/GetNavigationList")); }
        catch (error) { return error; }
    }

    /**
     * Devolve todas as permissoes criadas
     * @returns 
     */
    async GetMenuListScopes(): Promise<IAPIResponse<any>> {

        // const params = `idScope=${idScope}&idMenu=${idMenu}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>("Backoffice/Menu/GetScopeList")); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idScope 
     * @param idMenu 
     * @returns 
     */
    async GetOperationList(idScope?: string, idMenu?: string): Promise<IAPIResponse<any>> {

        // const params = `idScope=${idScope}&idMenu=${idMenu}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Menu/GetOperationList?idScope=${idScope || ''}&idMenu=${idMenu || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetMenuTabList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>("Backoffice/Menu/GetTabList")); }
        catch (error) { return error; }
    }


    /**
     * devolve lista de menus de todo projeto para configuração
     * @returns 
     */
    async GetMenuList(idPlatform?: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Menu/GetList?idPlatform=${idPlatform || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * devolve menu para configuração
     * @returns 
     */
    async GetMenu(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Menu/Get?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveMenu(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Menu/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteMenu(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Menu/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param menus 
     * @returns 
     */
    async ChangePositionMenu(menus: { Id: number, Position: number, IdParent?: number }[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Menu/ChangePosition`, menus)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetAvailableLanguages(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Generic/Language/GetList`)); }
        catch (error) { return error; }
    }

    //#endregion


    //#region PLACES

    /**
     * detalhes da freguesias
     * @param idYeabook
     * @param id 
     * @returns 
     */
    async GetPlacesDetails(idYeabook: string, id?: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/YearBook/Get?id=${idYeabook}&idYeabookData=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * Guardar os dados das freguesias
     * @param data 
     * @returns 
     */
    async SavePlaces(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * lista das freguesias
     * @param filters 
     * @returns 
     */
    async GetPlacesList(filters: IFilterList, IdType: string): Promise<IAPIPaginationResponse<any>> {

        const params: string = `PageNumber=${filters.PageNumber || ''}
								&PageRows=${filters.PageRows || ''}
								&Text=${filters.Text || ''}
								&IdType=${IdType || ''}
								`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Geography/Place/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param filters 
     * @param Type 
     * @param idCalendarYear 
     * @returns 
     */
    async GetPlacesAndUnionsList(filters: IFilterList, Type?: string, idCalendarYear?: string): Promise<IAPIPaginationResponse<any>> {

        const params: string = `PageNumber=${filters.PageNumber || ''}
								&PageRows=${filters.PageRows || ''}
								&Text=${filters.Text || ''}
								&Type=${Type || ''}
								&idCalendarYear=${idCalendarYear || ''}
								`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * SEACH ASSEST FROM SIG
     * @param text 
     * @returns 
     */
    async SeachFromSig(text): Promise<IAPIResponse<any>> {



        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/SearchFromSig?text=${text}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveYearBook(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async DeleteYearBook(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Delete?id=${id}`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param filters 
     * @returns 
     */
    async GetAssetsList(filters: IFilterPublicAssetsList): Promise<IAPIPaginationResponse<any>> {

        // Search search, string idPlace_parish = null, long? idType = null, long? idPlaceTypeResponsible = null, string idLawCategory = null
        const params: string = `PageNumber=${filters?.PageNumber || ''}
								&PageRows=${filters?.PageRows || ''}
								&Text=${filters?.Text || ''}
								&idPlace_parish=${filters?.idPlace_parish || ''}
								&idYearBook=${filters?.idYearBook || ''}
								&idType=${filters?.idType || ''}
								&idPlaceTypeResponsible=${filters?.idPlaceTypeResponsible || ''}
								&isHeritage=${filters?.isHeritage ?? ''}
								&idLawCategory=${filters?.idLawCategory || ''}
								&orderDescending=${filters?.OrderDescending || ''}
								`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/GetList?${params}`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param id 
     * @returns 
     */
    async GetAssetDetails(id: string): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Asset/Get?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCategory
     * @returns 
     */
    async GetSpaceTypeList(idCategory: string = ''): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/SpaceType/GetList?idCategory=${idCategory}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetSpaceStatusList(): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/SpaceStatus/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idYearBook 
     * @returns 
     */
    async GetSpaceList(idYearBook): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/YearBook/Space/GetList?idYearBook=${idYearBook || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idAsset
     * @param formData 
     * @returns 
     */
    async UploadAssetCoordinatesAndGetUrl(idAsset: string = '', formData?: FormData): Promise<IAPIResponse<string>> {

        try { return this.handleAuthResponse(await this.RequestPOSTFormData<string>(`Backoffice/Asset/UploadCoordinatesFile?idAsset=${idAsset}`, formData)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveAssetDoorNumber(data: any): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/DoorNumber/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteAssetDoorNumber(id: number): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/DoorNumber/Delete?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetClassificationSpaceType(id: number): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/ClassificationType/Get?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idSpaceType 
     * @returns 
     */
    async GetClassificationSpaceTypeList(idSpaceType: number): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/ClassificationType/GetList?idSpaceType=${idSpaceType || ''}`)); }
        catch (error) { return error; }
    }
    //#endregion


    //#region PROTOCOLS
    /**
     * 
     * @param idType 
     * @param filters
     * @returns 
     */
    async GetProtocolsList(idType: string, filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = `
						PageNumber=${filters?.PageNumber || ''}
						&PageRows=${filters?.PageRows || ''}
						&Text=${filters?.Text || ''}
						&IdType=${idType || ''}
						&IdContext=${filters?.IdContextType || ''}
						&IdYearBook=${filters?.IdYearBook || ''}
					`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/Category/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetProtocolById(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Law/Category/Get?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveProtocol(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/Category/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteProtocol(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/Category/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idType 
     * @param filters 
     * @returns 
     */
    async GetSubProtocolsList(idType: string, filters: IFilterList, idCategory?: string): Promise<IAPIPaginationResponse<any>> {

        const params = `
						PageNumber=${filters?.PageNumber || ''}
						&PageRows=${filters?.PageRows || ''}
						&Text=${filters?.Text || ''}
						&Type=${idType || ''}
						&idCategory=${idCategory || ''}
					`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/SubCategory/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idType 
     * @param filters 
     * @param idCategory 
     * @returns 
     */
    async GetSubProtocolsListAvailable(idType: string, filters: IFilterList, idYearBook: string, idCategory?: string, idBudget?: string, idBudgetDetails?: string): Promise<IAPIPaginationResponse<any>> {

        const params = `
						PageNumber=${filters?.PageNumber || ''}
						&PageRows=${filters?.PageRows || ''}
						&Text=${filters?.Text || ''}
						&Type=${idType || ''}
						&idCategory=${idCategory || ''}
						&idBudget=${idBudget || ''}
						&idYearBook=${idYearBook || ''}
						&idBudgetDetails=${idBudgetDetails || ''}
					`;

        //(Search search, string idSpaceType = null, string idContext = null)

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/SubCategory/GetListBudget?${params}`)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveProtocolSubCategory(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/SubCategory/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetProtocolSubcategoryById(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Law/SubCategory/Get?id=${id}`)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteProtocolSubCategory(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/SubCategory/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCategory 
     * @returns 
     */
    async GetLawArticleList(idCategory: string = "", idLawArticleType: string = ""): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/Article/GetList?idCategory=${idCategory || ''}&idLawArticleType=${idLawArticleType || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetLawTypologyList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Law/Typology/GetList`)); }
        catch (error) { return error; }
    }
    //#endregion


    //#region CONTENT METHODS
    /**
     * @param filters
     * @returns 
     */
    async GetContentList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = `PageNumber=${filters?.PageNumber || ''}
						&PageRows=${filters?.PageRows || ''}
						&Text=${filters?.Text || ''}
						&OrderDescending=${filters?.OrderDescending || ''}`;


        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Content/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * @param id
     * @returns 
     */
    async DeleteContentItem(id: number): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Content/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetContentDetails(id: number, idEdition: number = 0): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Content/Get?id=${id}&idEdition=${idEdition || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * @param data
     * @returns 
     */
    async SaveContentData(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Content/Save`, data)); }
        catch (error) { return error; }
    }
    //#endregion


    //#region NOTIFICATIONS
    /**
     * @param filters
     * @returns 
     */
    async GetNotificationList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = `PageNumber=${filters?.PageNumber || ''}
						&PageRows=${filters?.PageRows || ''}
						&Text=${filters?.Text || ''}
						&OrderDescending=${filters?.OrderDescending || ''}
						&WasViewed=${filters?.WasViewed}`;


        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Notification/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * @param id
     * @returns 
     */
    async GetNotificationDetail(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Notification/Get?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async MarkAsUnreadedNotification(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Notification/MarkAsUnreaded?IdNotification=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetCountNotification(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Notification/Count`)); }
        catch (error) { return error; }
    }

    //#endregion


    //#region FINANCIAL MANAGEMENTS

    /**
     * 
     * @param filters 
     * @returns 
     */
    async GetManagementYearBookOverviewList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = `
			PageNumber=${filters?.PageNumber || ''}
			&PageRows=${filters?.PageRows || ''}
			&Text=${filters?.Text || ''}
			&idCalendarYear=${filters?.IdEdition || ''}
		`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Budget/GetOverview?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param filters 
     * @param idLawCategory 
     * @param idPlaceTypeResponsible
     * @param idYearBook 
     * @returns 
     */
    async GetManagementYearBookList(filters: IFilterPublicAssetsList, idYearBook?): Promise<IAPIPaginationResponse<any>> {

        const params = `
			PageNumber=${filters?.PageNumber || ''}
			&PageRows=${filters?.PageRows || ''}
			&Text=${filters?.Text || ''}
			&idLawCategory=${filters?.idLawCategory || ''}
			&idLawSubCategory=${filters?.idLawSubCategory || ''}
			&idYearBook=${idYearBook || ''}
			&idCalendarYear=${filters?.IdEdition || ''}
			&idPlaceTypeResponsible=${filters?.IdPlaceTypeResponsible || ''}
		`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Budget/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetManagementYearBookDetails(id: string): Promise<IAPIPaginationResponse<any>> {

        const params = `id=${id || ''}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/YearBook/Budget/GetDetails?${params}`)); }
        catch (error) { return error; }
    }

    // async GetManagementYearBookDetails(id: string): Promise<IAPIPaginationResponse<any>> {

    // 	const params = `id=${id || ''}`;

    // 	try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/YearBook/Budget/Get?${params}`)); }
    // 	catch (error) { return error; }
    // }
    /**
     * 
     * @param data 
     * @param idYearBook
     * @returns 
     */
    async SaveManagementYearBook(data: any, idYearBook: string): Promise<IAPIPaginationResponse<any>> {

        const params = data;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Budget/SaveDetails?idYearBook=${idYearBook || ''}`, params)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveManagementYearBookBudgetTransfer(data: any): Promise<IAPIPaginationResponse<any>> {

        const params = data;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Budget/SaveTrancheDetails`, params)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async DeleteManagementYearBookBudgetTransfer(data: any): Promise<IAPIPaginationResponse<any>> {

        const params = data;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Budget/CancelTranche`, params)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteManagementYearBook(id: string): Promise<IAPIPaginationResponse<any>> {

        const params = `id=${id || ''}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/Budget/DeleteDetails?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetListInterventionAgreementType(): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Management/InterventionAgreementType/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idYearBook 
     * @param idCalendarYear 
     * @param idLawCategory 
     * @param idLawSubCategory 
     * @returns 
     */
    async GetYearBookBudgetSimpleList(idYearBook: string, idCalendarYear: string, idLawCategory: string, idLawSubCategory: string): Promise<IAPIResponse<any>> {
        // string idYearBook, string idCalendarYear, string idLawCategory, string idLawSubCategory
        const params = `idYearBook=${idYearBook}&idCalendarYear=${idCalendarYear}&idLawCategory=${idLawCategory}&idLawSubCategory=${idLawSubCategory}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Management/YearBook/BudgetSimple/GetList?${params}`)); }
        catch (error) { return error; }
    }

    //#endregion

    //#region INTERVENTIONS
    /**
     * 
     * @param id 
     * @returns 
     */
    async GetAssetInterventionDetails(id: string): Promise<IAPIResponse<any>> {

        const params = `id=${id || ''}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Asset/Intervention/Get?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveAssetIntervention(data: any, toSubmit: boolean = false, idContext: string = ''): Promise<IAPIResponse<any>> {

        const params = data;
        try { return this.handleAuthResponse(await this.RequestPOSTFormData<any>(`Backoffice/Asset/Intervention/Save?idContext=${idContext}&toSubmit=${toSubmit}`, params)); }
        catch (error) { return error; }

        // try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Intervention/Save?idContext=${idContext}&toSubmit=${toSubmit}`, params)); }
        // catch (error) { return error; }
    }

    /**
     * 
     * @param formData 
     * @returns 
     */
    async SaveAttachmentAssetIntervention(formData: FormData): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOSTFormData<any>(`Backoffice/Asset/Intervention/SaveAttachment`, formData)); }
        catch (error) { return error; }

    }

    /**
     * 
     * @param idAssetIntervention 
     * @param idAttachment 
     * @returns 
     */
    async DeleteAttachmentAssetIntervention(idAssetIntervention: number, idAttachment: number): Promise<IAPIResponse<any>> {

        const params = `idAssetIntervention=${idAssetIntervention}&idAttachment=${idAttachment}`
        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Intervention/DeleteAttachment?${params}`)); }
        catch (error) { return error; }

    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteAssetIntervention(id: string): Promise<IAPIResponse<any>> {

        const params = `id=${id || ''}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Intervention/Delete?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param filters 
     * @returns 
     */
    async GetAssetInterventionOverviewList(filters: IFilterList, idLawCategoryContext: string = '0'): Promise<IAPIPaginationResponse<any>> {

        const params = `
			PageNumber=${filters?.PageNumber || ''}
			&PageRows=${filters?.PageRows || ''}
			&Text=${filters?.Text || ''}
			&idCalendarYear=${filters?.IdEdition || ''}
			&idLawCategoryContext=${idLawCategoryContext || ''}
			&OrderDescending=${filters?.OrderDescending || ''}
		`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Intervention/GetOverview/${idLawCategoryContext}?${params}`)); }
        catch (error) { return error; }

    }

    /**
     * 
     * @param filters 
     * @param idLawCategoryContext 
     * @returns 
     */
    async GetAssetInterventionList(filters: IFilterPublicAssetsList, idLawCategoryContext: string = '0'): Promise<IAPIPaginationResponse<any>> {

        const params = `
			PageNumber=${filters?.PageNumber || ''}
			&PageRows=${filters?.PageRows || ''}
			&Text=${filters?.Text || ''}
			&idYearBook=${filters?.idYearBook || ''}
			&idCalendarYear=${filters?.idCalendarYear || ''}
			&idLawCategoryContext=${idLawCategoryContext || ''}
			&OrderDescending=${filters?.OrderDescending || ''}
		`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Intervention/GetList?${params}`)); }
        catch (error) { return error; }

    }

    /**
     * 
     * @param formdata 
     * @returns 
     */
    async AssetInterventionChangeStatus(formdata: FormData): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOSTFormData<any>(`Backoffice/Asset/Intervention/ChangeProcess`, formdata)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idYearBook 
     * @param idAssetIntervention 
     * @returns 
     */
    async GetInterventionSimplifiedInfo(idContext: number, idYearBook: number, idAssetIntervention?: number): Promise<IAPIResponse<any>> {

        const params = `idContext=${idContext}
						&idYearBook=${idYearBook}
						&idAssetIntervention=${idAssetIntervention || ''}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Intervention/GetSimplifiedInfo?${params}`)); }
        catch (error) { return error; }
    }

    //#endregion

    //#endregion


    //#region REPORTS

    /**
     * 
     * @param idYearBook 
     * @param idBudget 
     * @param format 
     * @returns 
     */
    GetReportManagementGetInvestments(idCalendarYear: string, idYearBook: string, idBudgetDetails: number, format: number): Observable<any> {

        const params = `
			format=${format || ''}
			&idCalendarYear=${idCalendarYear || ''}
			&idYearBook=${idYearBook || ''}
			&idBudgetDetails=${idBudgetDetails || ''}
		`;

        try {
            return this.RequestGETDownloadFile(`Backoffice/Report/GetInvestments?${params}`);
        }
        catch (error) { return error; }

    }
    //#endregion

    //#region INTERNAL EXPENSES
    /**
     * 
     * @param idType 
     * @param filters
     * @returns 
     */
    async GetInternalExpensesList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = `
						PageNumber=${filters?.PageNumber || ''}
						&PageRows=${filters?.PageRows || ''}
						&Text=${filters?.Text || ''}
						&IdContext=${filters?.IdContextType || ''}
						&IdYearBook=${filters?.IdYearBook || ''}
					`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Expenses/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetInternalExpensesById(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Backoffice/Asset/Expenses/Get?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveInternalExpenses(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Expenses/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteInternalExpenses(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Backoffice/Asset/Expenses/Delete?id=${id}`)); }
        catch (error) { return error; }
    }


    //#endregion

    /**
     * UTILIZAR APENAS EM METODOS SEGUROS
     * @param response 
     * @returns 
     */
    private async handleAuthResponse<T>(response: IAPIResponse<T>) {

        if (!response) return;

        console.log("handler -> ", response.Status);

        if (!response.IsSuccessStatus
            && (response.Status == EnumAPIStatus.InvalidAccessToken
                // 
                // || response.Status == EnumAPIStatus.Unauthorized
            )) {

            this.serverSessionLossSubject.next(true);
        }

        return response;
    }
}
